jQuery(document).ready(function ($) {

    var pet_friendly_table = jQuery('#pet-friendly-listings').DataTable( {
        "searching": true,
        "columns": [
            { orderable: false },
            null,
            { orderable: false },
            { orderable: false },
            { orderable: false },
            { orderable: false },
            { orderable: false }
        ],
        "order": [[ 1, 'asc' ]]
    } );

    let categoryIndex = 4;
    let detailsIndex = 5;

    jQuery.fn.dataTable.ext.search.push(
        function( settings, data, dataIndex, rowObj, counter ) {
            let selectedCategory = jQuery('#categoryFilter').val();
            let category = data[categoryIndex];
            let details = data[detailsIndex];

            let selectedPetTypes = jQuery( '#petTypeFilter' ).val();

            let petTypesAllowed = pet_friendly_table
                .row(dataIndex)
                .nodes()
                .to$()
                .find('td[data-pet-types]')
                .data('pet-types');

            if( ! pet_listings_filter_by_category( category, selectedCategory ) ) return false;
            if( ! pet_listings_filter_by_allowed_types( petTypesAllowed, selectedPetTypes ) ) return false;

            if( pet_listings_filter_by_outdoor_only( details ) ) return false;
            if( pet_listings_filter_by_off_leash_only( details ) ) return false;
            if( pet_listings_filter_by_no_fee_only( details ) ) return false;

            return true;
        }
    );

    jQuery("#categoryFilter, #petTypeFilter").change(function (e) {
        pet_friendly_table.draw();
    });
    jQuery( ".restrictions input" ).on('click', function( e ) {
        pet_friendly_table.draw();
    });

    pet_friendly_table.draw();

});

function pet_listings_filter_by_category( category, selectedCategory ) {
    return category.indexOf(selectedCategory) >= 0;
}

function pet_listings_filter_by_allowed_types( petTypesAllowed, selectedPetTypes ) {
    if( selectedPetTypes.length === 0 ) return true;
    if( selectedPetTypes === "All Pets" ) return true;
    if( petTypesAllowed === "All Pets" ) return true;

    if( selectedPetTypes === "Dogs" ) {
        if( petTypesAllowed === "Dogs Welcome" ) return true;
    }
    if( selectedPetTypes === "Cats" ) {
        if( petTypesAllowed === "Cats Welcome" ) return true;
    }

    // If none of the criteria matched, return false.
    return false;
}

function pet_listings_filter_by_outdoor_only( details ) {
    if(jQuery("#allowedInside").is(':checked') === false) return false;

    return details.indexOf("Outdoor") >= 0;
}

function pet_listings_filter_by_off_leash_only( details ) {
    if(jQuery("#offLeash").is(':checked') === false) return false;

    return details.indexOf("Leash") >= 0;
}

function pet_listings_filter_by_no_fee_only( details ) {
    if(jQuery("#noFees").is(':checked') === false) return false;

    return details.indexOf("Fee") >= 0;
}